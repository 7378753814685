import { BigNumber, Contract, ethers } from "ethers";
import { fetchReserves, getDecimals, parseUnits, formatUnits } from "../ethereumFunctions";

const ERC20 = require("../build/ERC20.json");
const PAIR = require("../build/IFairSwapPair.json");
const PAIRERC20 = require("../build/IFairSwapERC20.json");

// Function used to add Liquidity to any pair of tokens or token-AUT
// To work correctly, there needs to be 9 arguments:
//    `address1` - An Ethereum address of the coin to add from (either a token or AUT)
//    `address2` - An Ethereum address of the coin to add to (either a token or AUT)
//    `amount1` - A float or similar number representing the value of address1's coin to add
//    `amount2` - A float or similar number representing the value of address2's coin to add
//    `amount1Min` - A float or similar number representing the minimum of address1's coin to add
//    `amount2Min` - A float or similar number representing the minimum of address2's coin to add
//    `routerContract` - The router contract to carry out this trade
//    `accountAddress` - An Ethereum address of the current user's account
//    `provider` - The current provider
//    `signer` - The current signer
export async function addLiquidity(
  address1,
  address2,
  amount1,
  amount2,
  amount1min,
  amount2min,
  routerContract,
  account,
  signer
) {
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);
  const amountIn1 = parseUnits(amount1, token1Decimals);
  const amountIn2 = parseUnits(amount2, token2Decimals);
  const amount1Min = parseUnits(amount1min, token1Decimals);
  const amount2Min = parseUnits(amount2min, token2Decimals);
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  let tx;
  if (BigNumber.from(await token1.allowance(account, routerContract.address)).lt(amountIn1)) {
    tx = await token1.approve(routerContract.address, amountIn1);
    await tx.wait();
  }
  if (BigNumber.from(await token2.allowance(account, routerContract.address)).lt(amountIn2)) {
    tx = await token2.approve(routerContract.address, amountIn2);
    await tx.wait();
  }

  const wethAddress = await routerContract.WETH();

  // console.log([
  //   address1,
  //   address2,
  //   amountIn1,
  //   amountIn2,
  //   amount1Min,
  //   amount2Min,
  //   account,
  //   deadline,
  // ]);

  if (address1 === wethAddress) {
    // Eth + Token
    console.log("Eth + Token");
    tx = await routerContract.addLiquidityETH(
      address2,
      amountIn2,
      amount2Min,
      amount1Min,
      account,
      deadline,
      { value: amountIn1 }
    );
    await tx.wait();
  } else if (address2 === wethAddress) {
    // Token + Eth
    console.log("Token + Eth");
    tx = await routerContract.addLiquidityETH(
      address1,
      amountIn1,
      amount1Min,
      amount2Min,
      account,
      deadline,
      { value: amountIn2 }
    );
    await tx.wait();
  } else {
    // Token + Token
    console.log("Token + Token");
    tx = await routerContract.addLiquidity(
      address1,
      address2,
      amountIn1,
      amountIn2,
      amount1Min,
      amount2Min,
      account,
      deadline
    );
    await tx.wait();
  }
}

// Function used to remove Liquidity from any pair of tokens or token-AUT
// To work correctly, there needs to be 9 arguments:
//    `address1` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `address2` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `liquidity_tokens` - A float or similar number representing the value of liquidity tokens you will burn to get tokens back
//    `amount1Min` - A float or similar number representing the minimum of address1's coin to recieve
//    `amount2Min` - A float or similar number representing the minimum of address2's coin to recieve
//    `routerContract` - The router contract to carry out this trade
//    `accountAddress` - An Ethereum address of the current user's account
//    `provider` - The current provider
//    `signer` - The current signer
export async function removeLiquidity(
  address1,
  address2,
  liquidity,
  amount1min,
  amount2min,
  routerContract,
  account,
  signer,
  factory
) {
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);
  const amount1Min = parseUnits(amount1min, token1Decimals);
  const amount2Min = parseUnits(amount2min, token2Decimals);
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);
  const wethAddress = await routerContract.WETH();
  const pairAddress = await factory.getPair(address1, address2);
  const pairERC20 = new Contract(pairAddress, PAIRERC20.abi, signer);

  let tx;
  if (BigNumber.from(await pairERC20.allowance(account, routerContract.address)).lt(liquidity)) {
    tx = await pairERC20.approve(routerContract.address, liquidity);
    await tx.wait();
  }

  console.log([
    address1,
    address2,
    Number(liquidity),
    Number(amount1Min),
    Number(amount2Min),
    account,
    deadline,
  ]);

  if (address1 === wethAddress) {
    console.log("Eth + Token")
    // Eth + Token
    tx = await routerContract.removeLiquidityETH(
      address2,
      liquidity,
      amount2Min,
      amount1Min,
      account,
      deadline
    );
    await tx.wait();
  } else if (address2 === wethAddress) {
    console.log("Token + Eth")
    // Token + Eth
    tx = await routerContract.removeLiquidityETH(
      address1,
      liquidity,
      amount1Min,
      amount2Min,
      account,
      deadline
    );
    await tx.wait();
  } else {
    console.log("Token + Token")
    // Token + Token
    tx = await routerContract.removeLiquidity(
      address1,
      address2,
      liquidity,
      amount1Min,
      amount2Min,
      account,
      deadline
    );
    await tx.wait();
  }
}

const quote = (amount1, reserve1, reserve2) => {
  const amount2 = amount1 * (reserve2 / reserve1);
  return [amount2];
};

// Function used to get a quote of the liquidity addition
//    `address1` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `address2` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `amountA_desired` - The prefered value of the first token that the user would like to deploy as liquidity
//    `amountB_desired` - The prefered value of the second token that the user would like to deploy as liquidity
//    `factory` - The current factory
//    `signer` - The current signer

async function quoteMintLiquidity(
  address1,
  address2,
  amountA,
  amountB,
  factory,
  signer
){
  const MINIMUM_LIQUIDITY = 1000;
  let reserveA = 0;
  let reserveB = 0;
  let totalSupply;
  [reserveA, reserveB, totalSupply] = await factory.getPair(address1, address2).then(async (pairAddress) => {
    if (pairAddress !== ethers.constants.AddressZero){
      const pair = new Contract(pairAddress, PAIR.abi, signer);
      const pairERC20 = new Contract(pairAddress, PAIRERC20.abi, signer);

      const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
      const _reserveA = reservesRaw[0];
      const _reserveB = reservesRaw[1];
    
      const _totalSupply = await pairERC20.totalSupply();
      return [_reserveA, _reserveB, _totalSupply]
    } else {
      return [0,0,0]
    }
  });

  totalSupply = Number(totalSupply);
  // Need to do all this decimals work to account for 0 decimal numbers
  if (totalSupply == 0){
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token2 = new Contract(address2, ERC20.abi, signer);
    const token1Decimals = Number(await getDecimals(token1));
    const token2Decimals = Number(await getDecimals(token2));
    const convertRate = 10**((token1Decimals+token2Decimals)/2);
    return parseInt(Math.sqrt(amountA * amountB) * convertRate - MINIMUM_LIQUIDITY);
  } else {
    return parseInt(Math.min(amountA * totalSupply / reserveA, amountB * totalSupply / reserveB));
  }
};

export async function quoteAddLiquidity(
  address1,
  address2,
  amountADesired,
  amountBDesired,
  routerContract,
  factory,
  signer
) {
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);
  const parsedAmountADesired = parseUnits(amountADesired, token1Decimals);
  const parsedAmountBDesired = parseUnits(amountBDesired, token2Decimals);

  const quoteAmounts = await routerContract.quoteAddLiquidity(
    address1, 
    address2, 
    parsedAmountADesired,
    parsedAmountBDesired,
    0, 
    0
  );

  const amountA = formatUnits(quoteAmounts[0], token1Decimals);
  const amountB = formatUnits(quoteAmounts[1], token2Decimals);
  const amountLiquidity = await quoteMintLiquidity(
    address1,
    address2,
    amountA,
    amountB,
    factory,
    signer
  );

  return [
    amountA,
    amountB,
    amountLiquidity
  ];
}

// Function used to get a quote of the liquidity removal
//    `address1` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `address2` - An Ethereum address of the coin to recieve (either a token or AUT)
//    `liquidity` - The amount of liquidity tokens the user will burn to get their tokens back
//    `factory` - The current factory
//    `signer` - The current signer

export async function quoteRemoveLiquidity(
  address1,
  address2,
  liquidity,
  factory,
  signer
) {
  const pairAddress = await factory.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);
  const pairERC20 = new Contract(pairAddress, PAIRERC20.abi, signer);

  const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
  const reserveA = reservesRaw[0];
  const reserveB = reservesRaw[1];

  const feeOn =
    (await factory.feeTo()) !== ethers.constants.AddressZero;
    
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);
  const token1Decimals = Number(await getDecimals(token1));
  const token2Decimals = Number(await getDecimals(token2));

  const kLast = formatUnits(await pair.kLast(), token1Decimals+token2Decimals);
 
  let totalSupply = Number(await pairERC20.totalSupply());

  if (feeOn && kLast > 0) {
    const rootK = Math.sqrt(reserveA * reserveB);
    const rootKLast = Math.sqrt(kLast);
    const feeLiquidity =
      (totalSupply * (rootK - rootKLast)) /
      (5 * rootK + rootKLast);
    totalSupply = totalSupply + feeLiquidity;
  }

  const Aout = reserveA * liquidity / totalSupply;
  const Bout = reserveB * liquidity / totalSupply;

  return [liquidity, Aout, Bout];
}
